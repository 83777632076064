var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Editar Compra ")]),_c('v-divider'),_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"py-3 px-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","required":"","label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}},on))]}}]),model:{value:(_vm.datePicker1),callback:function ($$v) {_vm.datePicker1=$$v},expression:"datePicker1"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.datePicker1 = false}},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.series,"label":"Serie"},model:{value:(_vm.editedItem.serie),callback:function ($$v) {_vm.$set(_vm.editedItem, "serie", $$v)},expression:"editedItem.serie"}}),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"label":"Numero de Factura"},model:{value:(_vm.editedItem.invoice),callback:function ($$v) {_vm.$set(_vm.editedItem, "invoice", $$v)},expression:"editedItem.invoice"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.providerList,"label":"Proveedor","item-text":"name","item-value":"id"},model:{value:(_vm.editedItem.provider_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "provider_id", $$v)},expression:"editedItem.provider_id"}},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"px-4 py-1"},[_vm._v("No existen proveedores relacionadas.")])])],2),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","required":"","label":"Fecha de vencimiento","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.due_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "due_date", $$v)},expression:"editedItem.due_date"}},on))]}}]),model:{value:(_vm.datePicker2),callback:function ($$v) {_vm.datePicker2=$$v},expression:"datePicker2"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.datePicker2 = false}},model:{value:(_vm.editedItem.due_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "due_date", $$v)},expression:"editedItem.due_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-attachment")]),_vm._v(" Adjuntar PDF ")],1)]}}])},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-success":_vm.uploadSuccess,"vdropzone-error":_vm.uploadError,"vdropzone-removed-file":_vm.fileRemoved}})],1),_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-attachment")]),_vm._v(" Adjuntar XML ")],1)]}}])},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-success":_vm.uploadSuccess2,"vdropzone-error":_vm.uploadError,"vdropzone-removed-file":_vm.fileRemoved}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-textarea',{attrs:{"label":"Descripcion"},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}})],1)],1),_c('v-card-actions',{staticClass:"pt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.gris,"disabled":_vm.gris || !_vm.valid},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }